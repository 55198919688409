/**********************************************************************
 * Name: balance.js
 * Author: Hoa Tang
 * Date: December 20, 2016
 *
 * Description:
 *********************************************************************/

var balance = angular.module('balance', []);
/**********************************************************************
 * Name: BalanceController.js
 * Author: Hoa Tang
 * Date: December 20, 2016
 *
 * Description:
 *********************************************************************/
balance.controller('BalanceController', function($scope, $http, $timeout) {
    $scope.clientId = '';
    $scope.cvv = '';
    $scope.balance = '';
    $scope.clientId_error = null;
    $scope.cvv_error = null;
    $scope.disableButton = false;

    $scope.checkBalance = function(clientId, cvv) {
        //disable submit button for 2s after click to prevent user from spamming
        $scope.disableButton = true;
        $timeout(function() {
           $scope.disableButton = false;
        }, 2000);
        var data = $.param({
                clientId: clientId,
                cvv: cvv
            });

        var config = {
            headers : {
                'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8;'
            }
        }

        var response = $http.post('/checkBalance', data, config);

        response.then(function onSuccess(response) {
            if (angular.isDefined(response.data.cvv)) {
                $scope.cvv_error = response.data.cvv[0];
            } else {
                $scope.cvv_error = null;
            }
            if (angular.isDefined(response.data.clientId)) {
                $scope.clientId_error = response.data.clientId[0];
            } else {
                $scope.clientId_error = null;
            }
            if (angular.isDefined(response.data.balance)) {
                if (response.data.status != 200) {
                    $scope.balance_error = response.data.message;
                } else {
                    $scope.balance_error = null;
                }
                $scope.balance = response.data.balance;
            } else {
                $scope.balance = null;
            }
        });
        response.catch(function onError(response) {
            console.log(response);
        });
    }
});