/*********************************************************************/
$(document).ready(function() {
    $('#sandozTermAndConditionLink').click(function () {
        $('#stiConsent').prop('disabled', false);
        $('#sandozConsent').prop('disabled', false);
    });

    $('#sendSandozRegistration').click(function () {
        this.disabled=true;
        this.innerHTML += '<i class="fa fa-spinner fa-spin"></i>';
        this.form.submit();
    });
});